import React from 'react'
import { Section, Text } from '../styles/globalStyle'
import Heading from '../components/heading'
import Footer from '../components/footer'

const Gpx = () => {
  return (
    <>
      <Section top="true">
        <Heading h4 className="heading">
          Parsing GPX file
        </Heading>

        <Text></Text>
      </Section>
      <Footer />
    </>
  )
}

export default Gpx
