import { degtodm } from '../utils/gcCalcs'
import GeographicLib from 'geographiclib'

const geod = GeographicLib.Geodesic.WGS84

export const geoCalcsTest = async () => {
  const coordALat = 60.14230095676834
  const coordAlon = 24.97468333333334
  const coordBLat = 60.14275
  const coordBlon = 24.974681
  const direction = 90
  const distance = 1500
  const radiusA = 20
  const radiusB = 50
  //console.log('geodesic calculations called')
  // console.log('getDistance passes', coordALat, coordAlon, coordBLat, coordBlon)
  const distTest = await getDistance(coordALat, coordAlon, coordBLat, coordBlon)
  console.log('distance test', distTest, 'm')
  const projTest = await getProjection(
    coordALat,
    coordAlon,
    direction,
    distance
  )
  // console.log('projection test', projTest)
  // console.log('proj', degtodm(Math.abs(projTest.lat2), 3))
  // console.log('proj', degtodm(Math.abs(projTest.lon2), 3))
  console.log(
    'proj test',
    degtodm(Math.abs(projTest.lat2), 3),
    degtodm(Math.abs(projTest.lon2), 3)
  )
  // {projTest.lat2 < 0 ? 'S ' : 'N '}
  //   {degtodm(Math.abs(projTest.lat2), 3)}{' '}
  //   {projTest.lon2 < 0 ? 'W ' : 'E '}
  //   {  degtodm(Math.abs({projTest.lon2), 3)}
  const interceptTest = await getCircleIntersects(
    coordALat,
    coordAlon,
    radiusA,
    coordBLat,
    coordBlon,
    radiusB
  )
  console.log('interceptTest circle', interceptTest)
}

export const getDistance = async (
  coordALat,
  coordAlon,
  coordBLat,
  coordBlon
) => {
  const dist = await geod.Inverse(coordALat, coordAlon, coordBLat, coordBlon)
  return dist.s12.toFixed(3)
}

export const getProjection = async (
  coordALat,
  coordAlon,
  direction,
  distance
) => {
  const proj = await geod.Direct(coordALat, coordAlon, direction, distance)
  return proj
}

export const getCircleIntersects = async (
  coordALat,
  coordAlon,
  radiusA,
  coordBLat,
  coordBlon,
  radiusB
) => {
  const distAB = await getDistance(coordALat, coordAlon, coordBLat, coordBlon)

  if (
    Math.abs(radiusA + radiusB - distAB.dist) < 1 ||
    Math.abs(Math.abs(radiusA - radiusB) - distAB.dist) < 1
  ) {
    return [geodesicDirect(coordALat, coordAlon, distAB.startbrng, radiusA)]
  }

  const aInXyz = convertToXyz(coordALat, coordAlon)
  const bInXyz = convertToXyz(coordBLat, coordBlon)
  const dirA = await geodesicDirect(coordALat, coordAlon, 0, radiusA)
  const dirAXyz = convertToXyz(dirA.lat, dirA.lon)
  const dirB = await geodesicDirect(coordBLat, coordBlon, 0, radiusB)
  const dirBXyz = convertToXyz(dirB.lat, dirB.lon)

  const distAXyz = Math.sqrt(
    (aInXyz.x - dirAXyz.x) * (aInXyz.x - dirAXyz.x) +
      (aInXyz.y - dirAXyz.y) * (aInXyz.y - dirAXyz.y) +
      (aInXyz.z - dirAXyz.z) * (aInXyz.z - dirAXyz.z)
  )

  const distBXyz = Math.sqrt(
    (bInXyz.x - dirBXyz.x) * (bInXyz.x - dirBXyz.x) +
      (bInXyz.y - dirBXyz.y) * (bInXyz.y - dirBXyz.y) +
      (bInXyz.z - dirBXyz.z) * (bInXyz.z - dirBXyz.z)
  )

  const m = aInXyz.y * bInXyz.z - bInXyz.y * aInXyz.z
  const o = aInXyz.z * bInXyz.x - bInXyz.z * aInXyz.x
  const q = aInXyz.x * bInXyz.y - bInXyz.x * aInXyz.y
  const p = m * m + o * o + q * q

  if (p === 0) {
    return null
  }

  const r =
    aInXyz.x * (81179511356162 - distBXyz * distBXyz) -
    bInXyz.x * (81179511356162 - distAXyz * distAXyz)
  const s =
    aInXyz.y * (81179511356162 - distBXyz * distBXyz) -
    bInXyz.y * (81179511356162 - distAXyz * distAXyz)
  const t =
    aInXyz.z * (81179511356162 - distBXyz * distBXyz) -
    bInXyz.z * (81179511356162 - distAXyz * distAXyz)
  const w = 162359022712324 * p - (s * s + t * t + r * r)

  if (w < 0) {
    return null
  }

  const z = (o * t - q * s + m * Math.sqrt(w)) / (2 * p)
  const v = (q * r - m * t + o * Math.sqrt(w)) / (2 * p)
  const u = (m * s - o * r + q * Math.sqrt(w)) / (2 * p)
  const A = (o * t - q * s - m * Math.sqrt(w)) / (2 * p)
  const B = (q * r - m * t - o * Math.sqrt(w)) / (2 * p)
  const C = (m * s - o * r - q * Math.sqrt(w)) / (2 * p)
  const D = (Math.asin(u / Math.sqrt(z * z + v * v + u * u)) * 180) / Math.PI
  const y = (Math.atan2(v, z) * 180) / Math.PI
  const x = (Math.asin(C / Math.sqrt(A * A + B * B + C * C)) * 180) / Math.PI
  const G = (Math.atan2(B, A) * 180) / Math.PI

  let E,
    F = 0
  let intCircTest = {}
  let resultCoords = {}
  let intCircTest2 = {}
  let resultCoords2 = {}
  let crLat1 = D
  let crLon1 = y
  let crLat2 = x
  let crLon2 = G
  // loop ten times to find the first intersection of the circle
  // finds close enough proximation

  for (E = 0; E < 10; E++) {
    const ee = await azimuthalEquidistantForward(
      crLat1,
      crLon1,
      coordALat,
      coordAlon
    )
    const ii = await azimuthalEquidistantForward(
      crLat1,
      crLon1,
      coordBLat,
      coordBlon
    )
    intCircTest = intersectCirclesEAP(ee, radiusA, ii, radiusB)
    resultCoords = azimuthalEquidistantReverse(
      crLat1,
      crLon1,
      intCircTest.x,
      intCircTest.y
    )
    crLat1 = resultCoords.lat
    crLon1 = resultCoords.lng
  }

  for (F = 0; F < 10; F++) {
    const getDist1 = await azimuthalEquidistantForward(
      crLat2,
      crLon2,
      coordALat,
      coordAlon
    )
    const getDist2 = await azimuthalEquidistantForward(
      crLat2,
      crLon2,
      coordBLat,
      coordBlon
    )
    intCircTest2 = intersectCirclesEAP(getDist1, radiusA, getDist2, radiusB)
    resultCoords2 = azimuthalEquidistantReverse(
      crLat2,
      crLon2,
      intCircTest2.x,
      intCircTest2.y
    )
    crLat2 = resultCoords2.lat
    crLon2 = resultCoords2.lng
  }

  return [
    {
      lat: crLat1,
      lon: crLon1,
    },
    {
      lat: crLat2,
      lon: crLon2,
    },
  ]
}

export const geodesicDirect = async (
  coordALat,
  coordAlon,
  direction,
  radius
) => {
  var dist = await geod.Direct(coordALat, coordAlon, direction, radius)
  if (dist.azi2 < 0) {
    dist.azi2 += 360
  }
  return {
    lat: dist.lat2,
    lon: dist.lon2,
    startbrng: direction,
    endbrng: dist.azi2,
  }
}

//Cartesian Coordinates (XYZ) allow for Geodetic quality three dimensional positioning on an earth centered ellipsoid
export const convertToXyz = (lat, lon) => {
  const latInRad = (lat * Math.PI) / 180
  const lonInRad = (lon * Math.PI) / 180
  // average earth circle 6371.009 km
  // wgs defines it as 6378.137

  // const xInM = 6378137 * Math.cos(lonInRad) * Math.cos(latInRad)
  // const yInM = 6378137 * Math.sin(lonInRad) * Math.cos(latInRad)
  // const zInM = 6378137 * Math.sin(latInRad)
  const xInM = 6371009 * Math.cos(lonInRad) * Math.cos(latInRad)
  const yInM = 6371009 * Math.sin(lonInRad) * Math.cos(latInRad)
  const zInM = 6371009 * Math.sin(latInRad)

  return {
    x: xInM,
    y: yInM,
    z: zInM,
  }
}

export const azimuthalEquidistantForward = async (
  coordALat,
  coordAlon,
  coordBLat,
  coordBlon
) => {
  var dist = await geod.Inverse(coordALat, coordAlon, coordBLat, coordBlon)
  dist.azi1 *= GeographicLib.Math.degree
  return {
    x: dist.s12 * Math.sin(dist.azi1),
    y: dist.s12 * Math.cos(dist.azi1),
  }
}

const intersectCirclesEAP = (a, b, c, h) => {
  var f = c.x - a.x
  var d = c.y - a.y
  var i = Math.sqrt(f * f + d * d)
  var j = (i * i + b * b - h * h) / (2 * i)
  var l = Math.sqrt(b * b - j * j)
  var k = a.x + (f * j) / i + (d / i) * l
  var n = a.y + (d * j) / i - (f / i) * l
  var m = a.x + (f * j) / i - (d / i) * l
  var o = a.y + (d * j) / i + (f / i) * l
  if (Math.sqrt(k * k + n * n) < Math.sqrt(m * m + o * o)) {
    return {
      x: k,
      y: n,
    }
  }
  return {
    x: m,
    y: o,
  }
}

const azimuthalEquidistantReverse = (a, b, c, h) => {
  var f = Math.atan2(c, h) / GeographicLib.Math.degree
  var d = GeographicLib.Math.hypot(c, h)
  var i = geod.Direct(a, b, f, d)
  return {
    lat: i.lat2,
    lng: i.lon2,
  }
}
