import lineIntersect from '@turf/line-intersect'
import { lineString } from '@turf/helpers'
import { getCircleIntersects } from './geodesicCalculations'
const circleToPolygon = require('circle-to-polygon')

const findCrossingPoint = async ({ coordinates }) => {
  let geoJSON = {
    name: 'CrossingPoints',
    type: 'FeatureCollection',
    features: [],
  }

  const linesToTest = coordinates.length
  let x,
    y = 0
  for (x in coordinates) {
    if (linesToTest === Number(x) + 1) {
      break
    }

    const remainingItems = coordinates.slice(Number(x) + 1)

    for (y in remainingItems) {
      if (coordinates[x].direction !== 0) {
        if (remainingItems[y].direction !== 0) {
          const intersection = lineIntersect(
            lineString([
              [coordinates[x].longitude, coordinates[x].latitude],
              [coordinates[x].endLongitude, coordinates[x].endLatitude],
            ]),
            lineString([
              [remainingItems[y].longitude, remainingItems[y].latitude],
              [remainingItems[y].endLongitude, remainingItems[y].endLatitude],
            ])
          )
          geoJSON = appendToFeatures(intersection, geoJSON)
        }

        if (remainingItems[y].direction === 0) {
          const circleCenter = [
            remainingItems[y].longitude,
            remainingItems[y].latitude,
          ] //[lon, lat]
          const radius = remainingItems[y].lengthInMeters // in meters
          const numberOfEdges = 64 //optional that defaults to 32

          const polygon = circleToPolygon(circleCenter, radius, numberOfEdges)
          const lineCircleIntersection = lineIntersect(
            lineString([
              [coordinates[x].longitude, coordinates[x].latitude],
              [coordinates[x].endLongitude, coordinates[x].endLatitude],
            ]),
            polygon
          )
          geoJSON = appendToFeatures(lineCircleIntersection, geoJSON)
        }
      }

      if (coordinates[x].direction === 0) {
        if (remainingItems[y].direction === 0) {
          const circleCrossPoints = await getCircleIntersects(
            coordinates[x].latitude,
            coordinates[x].longitude,
            coordinates[x].lengthInMeters,
            remainingItems[y].latitude,
            remainingItems[y].longitude,
            remainingItems[y].lengthInMeters
          )

          if (circleCrossPoints) {
            const cr1 = {
              features: [
                {
                  type: 'Feature',
                  properties: {
                    name: 'circle cp1',
                  },
                  geometry: {
                    type: 'Point',
                    coordinates: [
                      circleCrossPoints[0].lon,
                      circleCrossPoints[0].lat,
                    ],
                  },
                },
              ],
            }

            geoJSON = {
              name: 'CrossingPoints',
              type: 'FeatureCollection',
              features: [...geoJSON.features, ...cr1.features],
            }

            if (circleCrossPoints.length === 2) {
              const cr2 = {
                features: [
                  {
                    type: 'Feature',
                    properties: {
                      name: 'circle cp2',
                    },
                    geometry: {
                      type: 'Point',
                      coordinates: [
                        circleCrossPoints[1].lon,
                        circleCrossPoints[1].lat,
                      ],
                    },
                  },
                ],
              }

              geoJSON = {
                name: 'CrossingPoints',
                type: 'FeatureCollection',
                features: [...geoJSON.features, ...cr2.features],
              }
            }
          }
        }
      }

      if (remainingItems[y].direction !== 0) {
        const circleCenterX = [
          coordinates[x].longitude,
          coordinates[x].latitude,
        ] //[lon, lat]
        const radiusX = coordinates[x].lengthInMeters // in meters
        const numberOfEdgesX = 64 //optional that defaults to 32

        const polygonX = circleToPolygon(circleCenterX, radiusX, numberOfEdgesX)

        const circleLineIntersection = lineIntersect(
          polygonX,
          lineString([
            [remainingItems[y].longitude, remainingItems[y].latitude],
            [remainingItems[y].endLongitude, remainingItems[y].endLatitude],
          ])
        )
        geoJSON = appendToFeatures(circleLineIntersection, geoJSON)
      }
    }
  }
  return geoJSON
}

export default findCrossingPoint

function appendToFeatures(intersection, geoJSON) {
  if (intersection.features.length > 0) {
    geoJSON = {
      name: 'CrossingPoints',
      type: 'FeatureCollection',
      features: [...geoJSON.features, ...intersection.features],
    }
  }
  return geoJSON
}
