// import React from 'react'
// eslint-disable-next-line
// import LatLon from 'geodesy/latlon-ellipsoidal-vincenty.js'
// eslint-disable-next-line
import LatLon from 'geodesy/latlon-ellipsoidal-vincenty'
// usage samples https://github.com/chrisveness/geodesy
import { float } from 'prop-types'

export const getEndPoint = props => {
  const { lat, lon, distance, direction } = props
  // console.log('we have', props, lat, lon, distance, direction)
  if (lat && lon && distance && direction) {
    // console.log('we are calculating ...', lat, lon, distance, direction)

    const p1 = new LatLon(lat, lon)
    const endPoint = p1.destinationPoint(distance, direction)
    // console.log('returning endPoint', endPoint)

    return endPoint
  }
  return null
}

getEndPoint.propTypes = {
  lat: float,
  lon: float,
  distance: float,
  direction: float,
}
