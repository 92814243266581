import React from 'react'
import { Section, Text } from '../styles/globalStyle'
import Heading from '../components/heading'
import Footer from '../components/footer'

const About = () => {
  return (
    <>
      <Section top="true">
        <Heading h4 className="heading">
          About
        </Heading>
        <Text>Just restructured this site, more content is coming.</Text>
        <Text>
          This site has been redesigned purposely to not use design frameworks.
        </Text>
        <Text>It is built using React, GraphQL, PostgreSQL and Django.</Text>
        <Text>
          You can try the new version of this site{' '}
          <a
            href="https://www.copperi.com/new"
            target="_blank"
            rel="noopener noreferrer"
          >
            here.
          </a>
        </Text>
      </Section>
      <Footer />
    </>
  )
}

export default About
