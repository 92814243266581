import React, { useEffect, useState } from 'react'
import { array, func, string, object } from 'prop-types'
import { Section } from '../../styles/globalStyle'
import findCrossPoints from '../../calculations/findCrossPoints'
import Button from '../button'
import Heading from '../heading'
import { TwentyTwoChText, CrossPointRow } from './maps.styles'
import { degtodm } from '../../utils/gcCalcs'

const CalculatedGeoPoints = props => {
  const { coordinates, currentDrawSet, setGeoJSONdata } = props
  const [findIntersections, setFindIntersections] = useState(false)
  const [crossingPoints, setCrossingPoints] = useState({})
  useEffect(() => {}, [crossingPoints])

  useEffect(() => {
    setCrossingPoints({})
  }, [currentDrawSet])

  if (!coordinates) {
    return <div>No coordinates.</div>
  }
  if (coordinates && coordinates.length === 0) {
    return <div></div>
  }
  if (findIntersections === true) {
    const crossp = findCrossPoints({ coordinates })
    crossp.then(cp => setCrossingPoints(cp))
    crossp.then(cp => setGeoJSONdata(cp))
    setFindIntersections(false)
  }

  if (crossingPoints && crossingPoints.features) {
    const cc = crossingPoints.features.map(c => c.geometry.coordinates)
    if (cc.length === 0) {
      return (
        <>
          <Heading h6 leftMargin="true" topMargin="true" bottomMargin="true">
            No crossing points found
          </Heading>
          <Button
            label="Search for new intersections"
            onClick={() => setFindIntersections(true)}
          ></Button>
        </>
      )
    }
    return (
      <>
        <Heading h6 leftMargin="true" topMargin="true">
          Crossing points found
        </Heading>
        {cc.map(c => (
          <CrossPointRow key={c[0] + c[1]}>
            <TwentyTwoChText>
              {c[1] < 0 ? 'S ' : 'N '}
              {degtodm(Math.abs(c[1]), 3)} {c[0] < 0 ? 'W ' : 'E '}
              {degtodm(Math.abs(c[0]), 3)}
            </TwentyTwoChText>
          </CrossPointRow>
        ))}
        <Button
          label="Recalculate intersections"
          onClick={() => setFindIntersections(true)}
        ></Button>
      </>
    )
  }

  return (
    <Section>
      <Button
        addTopMargin="true"
        label="Find intersections"
        onClick={() => setFindIntersections(true)}
      ></Button>
    </Section>
  )
}

CalculatedGeoPoints.propTypes = {
  coordinates: array,
  currentDrawSet: string,
  drawSetNames: object,
  loggedInUsername: string,
  setNotification: func,
  setGeoJSONdata: func,
}
export default CalculatedGeoPoints
